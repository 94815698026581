import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import styles from './allied-licensing-special.module.scss';
import AlliedLicensingSpecial from '../components/landing-page/allied-licensing-special';
import { Helmet } from 'react-helmet';
import { GatsbyImg } from '../services/img-location/img-location-service';

export default ({ data }) => {
  const gatsbyImg = new GatsbyImg('2022-licensure-special-allied', data);

  return (
    <>
      <Helmet>
        <title>Allied licensing special - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="For a limited time, we'll not only reimburse you for the costs of getting your new license in select states, we'll also send you a brand new iPad or Apple Watch during your assignment in that state."
        />
        <meta property="og:title" content="Allied licensing special" />
        <meta
          property="og:description"
          content="For a limited time, we'll not only reimburse you for the costs of getting your new license in select states, we'll also send you a brand new iPad or Apple Watch during your assignment in that state."
        />
        <meta property="og:image" content={gatsbyImg.ogImgUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/allied-licensing-special/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <AlliedLicensingSpecial fluid={gatsbyImg.fluidImg} />
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^landing-page/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
